@import "../../sass/variables";

.bottom-menus-wrapper {
  height: 78px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  background-color: #fff;
  z-index: 3;
}

@media (max-width: 900px) {
  .bottom-menus-wrapper {
    display: flex;
  }
}