.editor-container ul li{
  margin-left: 2.4rem;
}

.editor-container {
  white-space: pre-wrap;
}

.editor-container  img{
  max-width: 100%;
  margin: auto;
  display: block;
}

.bg-class *{
  background-color: #f6f6f6 !important;
}